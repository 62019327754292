
// --- Hero section ---
.hero-image {
  background-image: url('../../assets/images/programming-banner1.png');
  background-size: cover;
  position: relative;
  width: auto;
  height: 30%; }

.hero-text {
  text-align: center;
  color: navajowhite; }

.hero-title {
  font-weight: bold; }

.hero-sub-title {
  font-style: italic; }
// --- END Hero Section ---

// --- Services Section ---
.services {
  padding: 20px;

  .md-caroussel {
    height: 400px;
    width: 600px;
    max-width: 100%; }

  .process-image {
    height: 10rem;
    width: auto;
    margin: 1rem;
    align-content: start; } }
// --- END Services Section ---

.footer {
  background-color: darkgoldenrod; }

body {
  background-color: palegoldenrod; }

