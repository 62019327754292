/* Solarized Light

For use with Jekyll and Pygments

http://ethanschoonover.com/solarized

SOLARIZED HEX      ROLE
--------- -------- ------------------------------------------
base01    #586e75  body text / default code / primary content
base1     #93a1a1  comments / secondary content
base3     #fdf6e3  background
orange    #cb4b16  constants
red       #dc322f  regex, special keywords
blue      #268bd2  reserved keywords
cyan      #2aa198  strings, numbers
green     #859900  operators, other keywords
*/

$base03:            #002b36; // darkest grey
$base02:            #073642; // dark grey
$med-grey-dark:     #586e75; // med grey dark
$base00:            #657b83; // med grey
$base0:             #839496; // med grey light
$base1:             #93a1a1; // med grey lighter
$bg-error-shade:    #e3d2d2; // bg shading for errors
$bg-dark:           #ede7d5; // darker bg
$bg-shaded:         #eee8d5; // BG shaded
// $background:        #fdf6e3; // BG
$background:        #eeeee4; // BG
$yellow:    #b58900;
$orange:    #cb4b16;
$red:       #dc322f;
$magenta:   #d33682;
$violet:    #6c71c4;
$blue:      #268bd2;
$teal:      #2aa198;
$green:     #859900;   // Was going to call this olive
// $green:     #0c7f1c;    // was color of olive, but hard to tell from teal

/* Added from the beginning of _sass/_syntax-highlighting.scss
*/
.highlight {
    background: #fff;
    @extend %vertical-rhythm;

    .highlighter-rouge & {
    //   background: #eef;
      background: $background;
    }
}
// override the _base.scss
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: $background;
}

code {
    padding: 1px 5px;
}


//.highlight { background-color: #fdf6e3; color: $med-grey-dark; max-width: 56.25rem; padding: 1rem; border-radius: 1rem; margin: 0px auto 2em; }

.highlight { background-color: $background; color: $med-grey-dark; max-width: 56.25rem;padding: 1rem; border-radius: 1rem; margin: 0px auto 1em; }
.highlight .lineno { color: $base1 } /* Line Numbers */
.highlight .c { color: $base0; font-style: italic } /* Comment */
.highlight .c1 { color: $base0; font-style: italic } /* Comment */
.highlight .err { color: $med-grey-dark; background-color: $bg-error-shade  } /* Error */
.highlight .g { color: $med-grey-dark } /* Generic */
.highlight .k { color: $magenta } /* Keyword $green*/
.highlight .l { color: $med-grey-dark } /* Literal $med-grey-dark*/
.highlight .n { color: $blue } /* Name $med-grey-dark*/
.highlight .o { color: $med-grey-dark } /* Operator $green*/
.highlight .x { color: $orange } /* Other */
.highlight .p { color: $med-grey-dark } /* Punctuation */
.highlight .cm { color: $base1 } /* Comment.Multiline */
.highlight .cp { color: $green } /* Comment.Preproc */
.highlight .c1 { color: $base1 } /* Comment.Single */
.highlight .cs { color: $green } /* Comment.Special */
.highlight .gd { color: $teal; background-color: $bg-shaded } /* Generic.Deleted */
.highlight .gd .x { color: $teal; background-color: $bg-dark } // Generic.Deleted.Specific
.highlight .ge { color: $med-grey-dark; font-style: italic } /* Generic.Emph */
.highlight .gr { color: $red } /* Generic.Error */
.highlight .gh { color: $orange } /* Generic.Heading */
.highlight .gi { color: $green; background-color: $bg-shaded } /* Generic.Inserted */
.highlight .gi .x { color: $green; background-color: $bg-dark } // Generic.Inserted.Specific
.highlight .go { color: $med-grey-dark } /* Generic.Output */
.highlight .gp { color: $med-grey-dark } /* Generic.Prompt */
.highlight .gs { color: $med-grey-dark; font-weight: bold } /* Generic.Strong */
.highlight .gu { color: $orange } /* Generic.Subheading */
.highlight .gt { color: $med-grey-dark } /* Generic.Traceback */
.highlight .kc { color: $orange } /* Keyword.Constant */
.highlight .kd { color: $teal } /* Keyword.Declaration $blue*/
.highlight .kn { color: $green } /* Keyword.Namespace */
.highlight .kp { color: $green } /* Keyword.Pseudo */
.highlight .kr { color: $blue } /* Keyword.Reserved */
.highlight .kt { color: $red } /* Keyword.Type */
.highlight .ld { color: $med-grey-dark } /* Literal.Date */
.highlight .m { color: $teal } /* Literal.Number */
.highlight .s { color: $teal } /* Literal.String */
.highlight .na { color: $med-grey-dark } /* Name.Attribute */
.highlight .nb { color: $green } /* Name.Builtin #B58900*/
.highlight .nc { color: $blue } /* Name.Class $blue*/
.highlight .no { color: $orange } /* Name.Constant */
.highlight .nd { color: $blue } /* Name.Decorator */
.highlight .ni { color: $orange } /* Name.Entity */
.highlight .ne { color: $orange } /* Name.Exception */
.highlight .nf { color: $blue } /* Name.Function */
.highlight .nl { color: $violet } /* Name.Label $med-grey-dark*/
.highlight .nn { color: $med-grey-dark } /* Name.Namespace */
.highlight .nx { color: $med-grey-dark } /* Name.Other */
.highlight .py { color: $med-grey-dark } /* Name.Property */
.highlight .nt { color: $orange } /* Name.Tag $blue*/
.highlight .nv { color: $blue } /* Name.Variable */
.highlight .ow { color: $teal; font-weight: bold  } /* Operator.Word $green*/
.highlight .w { color: $med-grey-dark } /* Text.Whitespace */
.highlight .mf { color: $teal} /* Literal.Number.Float */
.highlight .mh { color: $teal } /* Literal.Number.Hex */
.highlight .mi { color: $teal } /* Literal.Number.Integer */
.highlight .mo { color: $teal } /* Literal.Number.Oct */
.highlight .sb { color: $teal } /* Literal.String.Backtick */
.highlight .sc { color: $teal } /* Literal.String.Char */
.highlight .sd { color: $med-grey-dark } /* Literal.String.Doc */
.highlight .s2 { color: $teal } /* Literal.String.Double */
.highlight .se { color: $orange } /* Literal.String.Escape */
.highlight .sh { color: $med-grey-dark } /* Literal.String.Heredoc */
.highlight .si { color: $teal } /* Literal.String.Interpol */
.highlight .sx { color: $teal } /* Literal.String.Other */
.highlight .sr { color: $red } /* Literal.String.Regex */
.highlight .s1 { color: $teal } /* Literal.String.Single */
.highlight .ss { color: $teal } /* Literal.String.Symbol */
.highlight .bp { color: $blue } /* Name.Builtin.Pseudo */
.highlight .vc { color: $blue } /* Name.Variable.Class */
.highlight .vg { color: $blue } /* Name.Variable.Global */
.highlight .vi { color: $blue } /* Name.Variable.Instance */
.highlight .il { color: $teal } /* Literal.Number.Integer.Long */